// This is just an example,
// so you can safely delete all default props below

export default {
	'name_organization': '',
	'client_signer_in': '',
	'The client based on field is required.': 'Подписант контрагента действует на основании обязательно к заполнению',
	'The client based on kz field is required.': 'Подписант контрагента действует на основании обязательно к заполнению (kz)',
	'name_organization_kz': '',
	'client_signer_in_kz': '',
	'bik': '',
	'address': '',
	'iik': ''
}

