import store from '../store'
export const guard = (to, from, next) => {
    const currentUser = store.getters.GET_TOKEN.length
    if (currentUser !== 0) {

        if(store.getters.GET_USER_DATA && store.getters.GET_USER_DATA.admin==true) {
            next({router:'/admin/panel'})
        }else {
            next({router:'/'})
        }
        
    } else {
        next({router:'/sign'})
    }
}
