export default {

	'The name organization kz field is required.' : 'Название организации обязательно к заполнению (kz)',
	'The client fio kz field is required.': 'ФИО в род. падеже обязательно к заполнению (kz)',
	'The client based on field is required.': 'Подписант контрагента действует на основании обязательно к заполнению',
	'The name organization field is required.': 'Название организации обязательно к заполнению',
	'The client fio field is required.': 'ФИО в род. падеже обязательно к заполнению',

	'name_organization': '',
	'client_signer_in': '',
	'The attachments.attachment 1. business identification number field is required.': 'Бизнес-идентификационный номер обязательно к заполнению',
	'The client based on kz field is required.': 'Подписант контрагента действует на основании обязательно к заполнению (kz)',
    'The attachments.attachment 1. vat payer number field is required.':'',
	'name_organization_kz': '',
	'client_signer_in_kz': '',
	'bik': '',
	'address': '',
	'iik': ''
}