
import { guard } from './guard'
export const routes = [{
        path: '/sign',
        name: 'AuthBank',
        component: () =>
            import ('../views/Auth.vue'),
    },
    {
        name: 'Main',
        path: "/",
        component: () =>
            import ("../views/Main.vue"),
        children: [{
                path: "/",
                component: () =>
                    import ("../components/ContractList.vue"),
                beforeEnter: guard
            },
            {
                path: "/contract",
                component: () =>
                    import ("../components/Contract/CreateContract.vue"),
                beforeEnter: guard
            },
            {
                path: "/contract/fasti/attachment/:id/:type",
                component: () =>
                    import (
                        "../components/Contract/fasti/CreateFastiSecondAttachment.vue"
                    ),
                beforeEnter: guard
            },

            {
                path: "/admin/update/fasti/attachment/:attach_id",
                component: () =>
                    import (
                        "../components/Contract/fasti/AdminUpdateFastiAttachment.vue"
                    ),
                beforeEnter: guard
            },
            {
                path: "/update/fasti/attachment/:id/:attach_id/:type",
                component: () =>
                    import (
                        "../components/Contract/fasti/CreateFastiSecondAttachment.vue"
                    ),
                beforeEnter: guard
            },
            {
                path: "/kcmr",
                component: () =>
                    import ("../components/KcmrContractList.vue"),
                beforeEnter: guard
            },
            {
                path: "/update",
                component: () =>
                    import ("../components/UpdateOrganization.vue"),
                beforeEnter: guard
            },
            {
                path: "/update/:id",
                component: () =>
                    import ("../components/UpdateOrganization.vue"),
                beforeEnter: guard
            },
            {
                path: "/update/contract/:id",
                component: () =>
                    import ("../components/Contract/UpdateSobsContract.vue"),
                beforeEnter: guard
            },

            {
                path: '/smk/view/:id',
                component: () =>
                    import ('../components/Contract/smk/ViewSmkUserDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/create/smk/attachment/:id/:attachment_id',
                component: () =>
                    import ('../components/Contract/smk/CreateSmkAttachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/smk/view/:id',
                component: () =>
                    import ('../components/Contract/smk/ViewAdminSmkDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/update/smk/:id',
                component: () =>
                    import ('../components/Contract/smk/UpdateSmkForm.vue'),
                beforeEnter: guard
            },
            {
                path: '/mspd/view/:id',
                component: () =>
                    import ('../components/Contract/mspd/ViewMspdUserDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/create/mspd/attachment/:id/:attachment_id',
                component: () =>
                    import ('../components/Contract/mspd/CreateMspdAttachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/mspd/update/:id',
                component: () =>
                    import ('../components/Contract/mspd/UpdateMspdContract.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/mspd/view/:id',
                component: () =>
                    import ('../components/Contract/mspd/ViewAdminMspdDocument.vue'),
                beforeEnter: guard
            },

            {
                path: '/visa/view/:id',
                component: () =>
                    import ('../components/Contract/visa/ViewVisaDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/visa/update/:id',
                component: () =>
                    import ('../components/Contract/visa/VisaUpdateForm.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/visa/view/:id',
                component: () =>
                    import ('../components/Contract/visa/ViewAdminVisaDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/sobs/view/:id',
                component: () =>
                    import ('../components/Contract/sobs/ViewSobsDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/sobs/view/:id',
                component: () =>
                    import ('../components/Contract/sobs/ViewAdminSobsDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/fasti/view/:id',
                component: () =>
                    import ('../components/Contract/fasti/ViewAdminFastiDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/fasti/view/:id',
                component: () =>
                    import ('../components/Contract/fasti/ViewFastiDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/sobs/attach/:contract_id',
                component: () =>
                    import ('../components/Contract/sobs/Attachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/mastercard/view/:id',
                component: () =>
                    import ('../components/Contract/mastercard/ViewMastercardDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/mastercard/view/:id',
                component: () =>
                    import ('../components/Contract/mastercard/ViewAdminMastercardDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/mastercard/update/:id',
                component: () =>
                    import ('../components/Contract/mastercard/MastercardUpdateForm.vue'),
                beforeEnter: guard
            },
            {
                path: '/smep/view/:id',
                component: () =>
                    import ('../components/Contract/smep/ViewSmepDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/smep/update/:id',
                component: () =>
                    import ('../components/Contract/smep/SmepUpdateForm.vue'),
                beforeEnter: guard
            },
            {
                path: '/coid_simple_license/view/:id',
                component: () =>
                    import ('../components/Contract/coid_simple/ViewCoidSimple.vue'),
                beforeEnter: guard
            },
            {
                path: '/coid_simple_license/update/:id',
                component: () =>
                    import ('../components/Contract/coid_simple/UpdateCoidSimple.vue'),
                beforeEnter: guard
            },
            {
                path: '/admin/coid_simple_license/view/:id',
                component: () =>
                    import ('../components/Contract/coid_simple/ViewAdminCoidSimple.vue'),
                beforeEnter: guard
            },

            {
                path: "/coid_notbank/view/:id",
                component: () =>
                    import ("../components/Contract/coid_not_bank/ViewNotBank.vue"),
                beforeEnter: guard
            },
            {
                path: "/coid_notbank/update/:id",
                component: () =>
                    import ("../components/Contract/coid_not_bank/UpdateNotBankForm.vue"),
                beforeEnter: guard
            },
            {
                path: "/admin/coid_notbank/view/:id",
                component: () =>
                    import ("../components/Contract/coid_not_bank/ViewAdminNotBank.vue"),
                beforeEnter: guard
            },
            {
                path: "admin/update/coid_notbank/attachment/:document_id/:id",
                component: () =>
                    import ("../components/Contract/coid_not_bank/UpdateCoidNotBankAttachment.vue"),
                beforeEnter: guard
            },

            {
                path: "/coid_service/view/:id",
                component: () =>
                    import ("../components/Contract/coid_service/ViewCoidService.vue"),
                beforeEnter: guard
            },
            {
                path: "admin/coid_service/view/:id",
                component: () =>
                    import ("../components/Contract/coid_service/ViewAdminCoidService.vue"),
                beforeEnter: guard
            },
            {
                path: "admin/update/coid_service/attachment/:document_id/:id",
                component: () =>
                    import ("../components/Contract/coid_service/UpdateAdminCoidServiceAttachment.vue"),
                beforeEnter: guard
            },
            {
                path: "/coid_service/update/:id",
                component: () =>
                    import ("../components/Contract/coid_service/UpdateCoidService.vue"),
                beforeEnter: guard
            },

            {
                path: "/uckcmr/view/:id",
                component: () =>
                    import ("../components/Contract/uckcmr/ViewUcKcmr.vue"),
                beforeEnter: guard
            },
            {
                path: "admin/uckcmr/view/:id",
                component: () =>
                    import ("../components/Contract/uckcmr/ViewAdminUcKcmr.vue"),
                beforeEnter: guard
            },
            {
                path: "/uckcmr/update/:id",
                component: () =>
                    import ("../components/Contract/CreateContract.vue"),
                beforeEnter: guard
            },

            {
                path: "/uckcmr/update/second/:id",
                component: () =>
                    import ("../components/Contract/uckcmr/UcKcmrUpdateSecondAttachment.vue"),
                beforeEnter: guard
            },


            {
                path: "/uckcmr/create/attachment/:id",
                component: () =>
                    import ("../components/Contract/uckcmr/CreateSecondAttachment.vue"),
                beforeEnter: guard
            },
            {
                path: "/uckcmr/update/second/attachment/:id",
                component: () =>
                    import ("../components/Contract/uckcmr/CreateSecondAttachment.vue"),
                beforeEnter: guard
            },

            {
                path: "/uckcmr/create/first/attachment/:document_id",
                component: () =>
                    import ("../components/Contract/CreateContract.vue"),
                beforeEnter: guard
            },

            {
                path: '/admin/uc/view/:id',
                component: () =>
                    import ('../components/Contract/uc/ViewAdminUcDocument.vue'),
                beforeEnter: guard
            },
            {
                path: 'admin/uc/update/:id/:attachment_id',
                component: () =>
                    import ('../components/Contract/uc/CreateAttachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/uc/view/:id',
                component: () =>
                    import ('../components/Contract/uc/ViewUcDocument.vue'),
                beforeEnter: guard
            },
            {
                path: '/uc/create/attachment/:id',
                component: () =>
                    import ('../components/Contract/uc/CreateAttachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/uc/update/contract/:id',
                component: () =>
                    import ('../components/Contract/uc/CreateAttachment.vue'),
                beforeEnter: guard
            },
            {
                path: '/uc/update/:id/:attachment_id',
                component: () =>
                    import ('../components/Contract/uc/CreateAttachment.vue'),
                beforeEnter: guard
            },

            // {
            //   path: '/admin/coid_notbank/view/:id',
            //   component: () => import('../components/Contract/coid_not_bank/ViewAdminNotBank.vue'),
            // },

            // {
            //   path: "/admin/smep/view/:id",
            //   component: () =>
            //     import("../components/Contract/smep/ViewAdminSmepDocument.vue"),
            // },

            {
                path: '/admin/smep/view/:id',
                component: () =>
                    import ('../components/Contract/smep/ViewAdminSmepDocument.vue'),
                beforeEnter: guard
            },

            {
                path: '/users',
                component: () =>
                    import ('../components/BankUsers.vue'),
                beforeEnter: guard
            },
            {
                path: '/passport/:id',
                component: () =>
                    import ('../components/DocumentPassport.vue'),
                beforeEnter: guard
            },
            {
                path: '/passport/list/:id',
                component: () =>
                    import ('../components/DocumentPassport.vue'),
                beforeEnter: guard
            },
            {
                path: "/admin/panel",
                name: "adminPanel",
                component: () =>
                    import ("../views/AdminPanel/AdminPanel.vue"),
                beforeEnter: guard
            },
            {
                path: "/admin/request/register",
                name: "SignRequest",
                component: () =>
                    import ("../components/SignRequest.vue"),
            },
            {
                path: "/admin/moderation",
                name: "Moderation",
                component: () =>
                    import ("../components/Moderation.vue"),
            },


        ],
    },



    {
        path: '/admin/sign',
        name: 'AuthAdmin',
        component: () =>
            import ('../views/AdminAuth.vue'),
    },
    {
        path: '/login',
        name: 'AuthLogin',
        component: () =>
            import ('../views/Auth/Login.vue')
    },
    {
        path: '/registration',
        name: 'Regitstration',
        component: () =>
            import ('../views/Auth/Registration.vue')
    },
    {
        path: '/recovery/:type',
        name: 'Recovery',
        component: () =>
            import ('../views/Auth/Recovery.vue')
    },

    {
        path: '/reset-password/:hash/:type',
        component: () =>
            import ('../views/Auth/SendPassword.vue')
    },




    {
        path: "/admin-bank/:bankId",
        name: "BankInform",
        component: () =>
            import ("../views/AdminPanel/BankInform.vue"),
        beforeEnter: guard
    },
    {
        path: "/admin-info/:bankId",
        name: "ChangeBankInfo",
        component: () =>
            import ("../views/AdminPanel/ChangeBankInfo.vue"),
    },
]
